.loading-animation {
    z-index: 10;
    display: flex;
  
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.7);
  }
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }
  
  .spinner-icon {
    color: #003468 !important;
  }
  
  .spinner {
    animation: spinner 1.0s linear infinite;
    // margin: auto;
  }
  
  .spinner-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color:rgba(255,255,255,0.7);
  }
