.search-container {
  position: relative;
  background-color: $blue;
  padding: 2.6em 0;
}

.search-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    margin: 10px 20px;
    .form-group{
        width: 67%;
        padding-right: 10px;
        padding-left: 10px;
        .custom-form__input {
            @include tablet {
                width: 300px;
            }
            height: 50px;
        }
        .text-input__icon-left{  //override css prism 
            z-index: 0;
        }
    }

  .custom-form__btn {
    background-color: $clr-btn;
    border-color: $clr-btn;
    width: 13%;
    height: 50px;
    font-size: 20px;

    &.btn-primary.disabled {
      color: #4a4a4a;
    }
  }

  .custom-form__btn:not(:disabled):not(.disabled):active {
    background-color: $clr-btn;
    border-color: $clr-btn;
  }
}

.search-user__table {
  margin: 30px 20px 0;
  border-radius: 8px;
  
  .hidden-col {
    height: 0;
    border-width: 0;
    padding: 0;
  }

  .AuctionBag {
    display: flex;
    background: #f6f6f6;
    border-radius: 5px;
    padding: 3px 5px;
    align-items: center;
    justify-content: space-between;
  }

  .user-role {
    .inner-table-error {
      display: inline-block;

      p {
        width: 180px;
      }
    }
    .dd-wrapper {
      position: inherit;
      width: 200px;
      .dd-list {
        width: 200px;
        max-height: 100%;
      }
    }
  }
}

table {
  width: 100%;
  thead {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    background-color: $blue-light;
  }

  th {
    padding: 10px 8px;
    letter-spacing: 0.1rem;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid $clr-border-light;
    text-align: center;
    color: $light;

  }

  td {
    min-width: 100px;
    max-width: 300px;
    padding: 10px 8px;
    border: 1px solid $clr-border-light;
    text-align: center;
    height: 80px;
    min-height: 60px;
    & > input {
      &.form-control {
        height: 38.15px
      }
    }

    .inner-table-error {
      text-align: left;

      p {
        color: #902b00;
        margin-bottom: 0px;
      }
    }
  }
  .btn_content_custom {
    width: 160px;
  }
  .select-auction{
    min-width: 200px;
    max-width: 300px;
  }
  .td-inventory{
    min-width: 180px;
  }
}

.btn-blue{
background-color: $btn-blue !important;
border: 1px solid $btn-blue !important;
}
.css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{ // custom css icon
  cursor: pointer !important;
}

.icon-block{
  display: none !important;
 }
 .disable-column{
   background-color: #babcbe;
   cursor: not-allowed;
 }

.no-full-width{
  width: 70%;
  margin: 30px auto 0;;
}

.link-service-now {
  display: flex;
  align-items: center;
  margin: 10px 20px 0;
  font-size: 14px;
  .link-hyper:hover {
    border-bottom: 1px solid #2c90cc !important;
  }
}

.modal-role{
  .modal-content{
    padding: 4px 7px 16px 7px !important;
  }
}

.icon-role{
  cursor: pointer;
  position: absolute;
  right: 0;
  top: calc(50% - 11px);
  padding-right: 5px;
}

@mixin customStyleBtnAddProduct($bg,$color){
  background-color: $bg;
  color: $color;
  .add-new-product > svg > path {
    fill: $color !important;
  }
}

#btn-add-product {
  @include customStyleBtnAddProduct(#005ba8,#fff);
  transition: all .3s;
  .add-new-product > svg{
    margin-bottom: 4px;
  }
  &:hover {
    &:disabled{
      .add-new-product {
        color:#4a4a4a;
      }
    }
    @include customStyleBtnAddProduct(#fff,#005ba8);
  }
  &:focus {
    box-shadow: none;
  }
  &:disabled{
    @include customStyleBtnAddProduct(#BABCBE,#4a4a4a)
  }
}

.cancel-icon{
  opacity: 0.7;
}
.cancel-icon:hover {
  opacity: 1;
}

.btn-icon:focus{
  outline: none !important;
  box-shadow: none !important;
}

.th-global-access{
  width: 80px;
}
.button-icon{
  pointer-events: none;
}
.th-role{
  width: 200px;
}

