.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 38px;
    max-width: 470px;
    padding: 0 4px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
    &:focus-within {
    border: 1px solid #0052cc;
    }
    input {
        flex: 1;
        border: none;
        font-size: 85%;
        padding-bottom: 3px;
        margin: 4px 4px 0 0;
        &:focus {
        outline: transparent;
        }
        min-width: 20% !important;
    }
}
    .tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 2px 4px 0 0;
    }
    .tag {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        padding: 1px 2px 1px 6px;
        font-size: 85%;
        list-style: none;
        border-radius: 4px;
        margin: 0 4px 0px 0;
        background: hsl(0, 0%, 90%);
        margin: 4px;
        .tag-close-icon {
            display: block;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 90%;
            font-weight: bold;
            margin-left: 8px;
            color: #000;
            cursor: pointer;
            margin-top: 1px;
        }
    } 
    @media screen and (max-width: 567px) {
        .tags-input {
            width: calc(100vw - 32px);
        }
    }