@import "@prism/styles/prism.comps.min.css";

@import "app.scss";
@import "dropdown.scss";
@import "error-page.scss";
@import "loading-animation.scss";
@import "search-input.scss";
@import "tags-input.scss";
@import "filter-table.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-15{
  width: 15% !important;
}
