#tab-filter-table .tab-pane {
  padding: 1rem 0 0 0 !important;
}

.text-err{
  color: #902b00;
}

.panel-outline {
  border: 1px solid #babcbe;
  border-radius: 5px;
  padding: 8px;
  margin: 16px 18px 8px 18px;
  background-color: #c1dff2;
}
.switch-status-text{
  display: none !important;
}
.btn-pagination{
  height: 10px !important;
  width: 35px;
}

.error-filter.prism-message{
  margin: 10px 0px;
}

.btn-edit-action {
  &.disabled {
    cursor: not-allowed;
    path{
      fill: #ccc !important;
    }
  }
}

.switch {
  input:checked:disabled + .switch-track {
    cursor: not-allowed;
  }
}

#btn-filter, #btn-clear-filter {
  font-weight: 500 !important;
  min-height: 32px !important;
  max-height: 38px;
}

#btn-filter {
  background-color: #2d5883;
  color: #fff ;
  transition: all .3s;
  &:hover{
    background-color: #154d85;
  }
 
}

#btn-clear-filter {
  background-color: #fff;
  color: #2d5883;
  transition: all .3s;
    &:hover{
      background-color: rgb(250, 242, 242);
    }
    &:disabled{
      // background-color: #BABCBE;
      color: #babcbe;
      cursor: not-allowed;
    }
}

.hover-row-on-table{
  background-color: #ebeef2;
}

.filter-table {
  .icon-header-filter-table {
    color: #003468;
    font-weight: bold;
  }
}