.error-boundary-page,
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  h2 {
    margin-bottom: 0;
  }
}
