.switch--custom {
    position: relative;
    display: inline-block;
    margin-left: 4px;
    width: 50px;
    height: 26px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .switch-slider {
            background-color: #2d5883;
        }
        &:checked + .switch-slider::before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
        &:focus + .switch-slider {
            box-shadow: 0 0 1px #2d5883;
        }
    }
    .switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        border: 3px solid #2d5883;
        -webkit-transition: .4s;
        transition: .4s;
        &::before {
            position: absolute;
            content: "";
            height: 28px;
            width: 28px;
            top: -4px;
            left: -4px;
            background-color: white;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
    .round {
        border-radius: 30px;
        &::before {
            border-radius: 50%;
        }
    }
}
.switch--custom.disabled{
    .switch-slider{
        cursor: not-allowed;
        background-color: #ccc !important;
        border-color: transparent;
    }
}