.td-gray-out {
  background-color: #eee;
}

.modal-content {
  max-height: unset;
  .btn-outline-secondary {
    border: 1px solid #005ba8
  }
  .modal-body {
    overflow-y: unset;
  }
  .modal-footer:not(:first-child) {
    .btn {
      margin-top: 0;
    }
  }
}

.link-service-now {
  margin: 10px 0;
}

.col-role, .col-product, .col-name {
  max-width: 200px;
  min-width: 150px;
}

.btn-main {
  background-color: #007bff;
  color: #fff;
  &:disabled {
    background-color: #babcbe;
    color: #4a4a4a;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: white;
    border: 1px #007bff solid;
    color: #007bff;
    svg > path {
      fill: #007bff !important;
    }
  }
}
.btn-icon {
  &:disabled {
    cursor: not-allowed;
  }
}
.bg-light-content{
  font-size: 14px;
  font-weight: normal;
}
.my-select-longer__menu{
  min-height: 200px;
 }
 .my-select-shorter__menu{
  min-height: 70px;
 }
 .case-invalid-data{
  svg{
    fill: orange;
  }
  p{
    display: none;
  }
 }
 .case-invalid-data.show{
  position: relative;
  p{
    display: block;
    position: absolute;
    top: 31px;
    left: 2%;
    width: 100%;
    color: orange;
    font-style: italic;
  }
 }

 .company-aim-container {
  max-width: 100px;
 }

 .uam-custom-input-group {
  width: 100%;

  p {
    margin: 0px;
    text-align: left;
  }

  input {
    min-height: 38px;
    padding: 0 4px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
    outline: none;
    margin: 6px 0px;

    &:focus,
    &:focus-visible {
      border: 1px solid #0052cc;
    }

    &.error {
      border-color: red;
    }
  }

  label {
    margin: 0px;
    display: flex;
    gap: 6px;
    font-size: 14px;
    color: red;
    text-transform: capitalize;

    i {
      display: flex;
      align-items: center;
    }
  }
 }
 