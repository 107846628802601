$blue: #003468;
$gold_dark: #ebb000;
$light: #f9fbff;
$gray: #eeeeee;
$clr-gray150: #f4f6fb;
$clr-sucess: #e3fad1;
$clr-success-font: #0d8240;
$clr-cerulean: #c1dff2;

$blue-light: #2d5883;
$clr-btn:#ffc20e;

$clr-border-light:#dddddd;

$btn-blue:#007bff;
