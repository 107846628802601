@import '_colors.scss';
@import 'media-queries.scss';

html {
    font-family: "Roboto", sans-serif;
    font-size: 62.5%;
}
  
body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    background-color: #f3f3f3;
}

.hide {
    display: none;
}

.padding_top_1{
    padding-top: 1px;
}

.modal-body {
    overflow-y: unset;
}

.modal-footer:not(:first-child) .btn {
    margin-top: 0;
}
