.dd-wrapper {
  position: relative;
  width: 20%;
  font-size: 1.6rem;
  user-select: none;
  display: inline-block;
  .dd-header,
  span {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 3px;
    width: 100%;
    background-color: white;
    line-height: 34.5px;
    cursor: default;
    cursor: pointer;
    &:hover {
      background-color: #eeeeee;
    }
  }
  .dd-header-title {
    margin: 2px 20px;
    margin-right: 30px;
    font-weight: 1.6rem;
    color: #000;
  }
  .angle-down {
    color: black;
  }
  .dd-list {
    overflow-y: auto !important;
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 330px !important;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: white;
    font-weight: 500;
    font-size: 1.6rem;
    text-align: left;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }
  .dd-list-item {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    padding: 15px 10px 10px 10px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      background-color: #eeeeee;
    }
  }
  .custom-icon {
    position: absolute;
  }
  .item-content{
    margin-left: 15px;
  }
  .item-content-search {
    margin-left: 30px;
  }

  button {
    &.dd-header {
      &:disabled:hover {
        cursor: default;
        background-color: white;
      }
    }
  }
}
